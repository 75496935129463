/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { PortableText } from "@portabletext/react";
import PortableTextComponent from "../components/serializers/portableTextComponents.js";
import "../scss/page/home.scss";
import "../scss/page/products.scss";

const PageDefinition = ({ data }) => {
  const node = data.page;
  const metaTitle = node.metaTitle ? node.metaTitle : node.title;
  const metaDescription = node.metaDescription ? node.metaDescription : "";
  return (
    <div id={node.slug.current}>
      <SEO title={metaTitle} lang="en" description={metaDescription}></SEO>
      <Layout type="page-outer">
        {node._rawEntities && (
          <div className="entities-container">
            <PortableText
              value={node._rawEntities}
              components={PortableTextComponent}
            />
          </div>
        )}
      </Layout>
    </div>
  );
};
export default PageDefinition;

export const pageDefinitionQuery = graphql`
  query PageDefinitionBySlug($slug: String!) {
    page: sanityPageDefinition(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      metaTitle
      metaDescription
      title
      _rawEntities(resolveReferences: { maxDepth: 15 })
    }
  }
`;
